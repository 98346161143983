/* eslint-disable */
import {BaseType} from "./BaseType";
import {MasterCardBuilder} from "../Builders/MasterCardBuilder";

class MasterCard extends BaseType {
    /**
     * Prepare the data for server request.
     * @param configurations
     * @returns {Promise<{data: {amount: string, payment_gateway: *, is_live: *, project: *, return_url: *, currency: string, payment_gateway_type: *, order_id: string}, info: *}>}
     */
    async initPaymentRequestData(configurations = {}) {
        return this.sdk.loadInfo()
            .then(info => {
                // map to push it to server
                configurations['customerOrderDate'] = configurations['customerOrderDate'] || this.getCurrentDate();
                configurations['order_description'] = configurations['order_description'] || '';

                let {currency: $infoCurrency = undefined} = info;
                let {currency: $userCurrency = undefined} = configurations;

                // update the config
                this.sdk.mergeConfig(configurations);

                let {
                    project,
                    payment_gateway,
                    payment_gateway_type,
                    is_live,

                    amount = '0.00',
                    order_id = '0',
                    return_url,

                    currency = null,
                    currencies = {},
                    region = undefined,
                    regions = []
                } = this.sdk.getAllData();

                currency = currency || $infoCurrency || $userCurrency;
                // noinspection EqualityComparisonWithCoercionJS
                if (currency != $infoCurrency) {
                    if (!region) {
                        throw new Error(`Region not set!`);
                    }
                    if (!regions) {
                        throw new Error(`No regions found!`);
                    }
                    if (!regions.includes(region)) {
                        throw new Error(`Not allowed to use the given region [${region}]!`);
                    }

                    if (!currencies) {
                        throw new Error(`No currencies found!`);
                    }
                    if (!Object.values(currencies).includes(currency)) {
                        throw new Error(`Not allowed to use the given currency [${currency}]!`);
                    }

                    // check user currency
                    if (String(currencies[region] || "").trim() !== String(currency).trim()) {
                        throw new Error(`Current region doesn't support the given currency [${currency}]!`);
                    }
                }

                // update currency config
                this.sdk.mergeConfig({currency});

                let data = {
                    project,
                    payment_gateway,
                    payment_gateway_type,
                    is_live,

                    amount,
                    order_id,
                    currency,
                    return_url,
                    region,
                };

                return {
                    data,
                    info,
                };
            })
    }

    /**
     * Init the payment.
     * @param configurations
     * @returns {Promise<*>}
     */
    async initPayment(configurations = {}) {
        let axios = await this.getAxios();

        return this.initPaymentRequestData(configurations)
            .then($data => {
                let {info, data} = $data;
                let {master_card_initiate} = info;

                return axios[master_card_initiate.method](master_card_initiate.path, data || {});
            })
    }

    /**
     * Transform init payment response.
     * @param success
     * @param payload
     * @returns {{success: boolean, is_live: {}|*|undefined, gateway: {}|*|undefined}}
     */
    initPaymentBuildResponse(success = true, payload = {}) {
        if (!success) {
            let gateway = this.sdk.getConfig('payment_gateway', payload && payload['gateway'] || undefined);
            // let is_live = this.sdk.getConfig('is_live', 0);

            if ("response" in payload && "data" in payload.response) {
                payload = payload.response['data'] || {};
            }

            return {
                success,
                gateway,
                errors: payload['errors'] || [],
                message: payload['message'] || ""
            };
        }

        return new MasterCardBuilder(payload, this.sdk);
        // return builder
        //     .setCompleteCallback((resultIndicator, sessionVersion) => (location.href = builder.getReturnUrl({resultIndicator, sessionVersion, status: 'complete'})))
        //     .setErrorCallback(error => (location.href = builder.getReturnUrl({error: JSON.stringify(error), status: 'error'})))
        //     .setCancelCallback(() => (location.href = builder.getReturnUrl({status: 'cancel'})))
        //     .setTimeoutCallback(() => (location.href = builder.getReturnUrl({status: 'timeout'})));
    }

    /**
     * Register payment response.
     * @param body
     * @returns {Promise<*>}
     */
    async registerPayment(body = {}) {
        let axios = await this.getAxios();

        return this.sdk.loadInfo()
            .then(info => {
                let {register} = info;
                body['successIndicator'] = localStorage.getItem('successIndicator');

                let payload = this.sdk.withBaseData({response_data: body});
                payload['is_live'] = undefined;
                delete payload['is_live'];

                return axios[register.method](register.path, payload);
            })
    }

    /**
     * Transform register payment response.
     * @param success
     * @param payload
     * @returns {{success: boolean, gateway: {}|*|undefined}}
     */
    registerPaymentBuildResponse(success = true, payload = {}) {
        let {
            gateway,
            message,
            data,
            response
        } = payload;

        gateway = gateway || this.sdk.getConfig('payment_gateway');
        response = response || {};
        data = data || response['data'] || {};
        message = message || data['message'] || "";
        let errors = [];

        let results = {
            success, gateway,
        };

        if (success) {
            results = {
                ...results, data
            }
        } else {
            if (response && 'data' in response) {
                data = response['data'] || {};

                if (data && 'data' in data) {
                    data = data['data'] || {};
                }

                if (data && 'message' in data) {
                    message = data['message'] || "";
                }

                errors = data && data['errors'] || [];
                message = data && data['message'] || message;
            }

            if (message && typeof message === 'object' && 'message' in message) {
                message = message['message'] || "";
            }

            results = {
                ...results,
                errors,
                message
            }
        }

        return results;
    }
}

const $exports = {
    async make(sdk, config = {}) {
        await sdk.init(config);
        return new MasterCard(sdk);
    }
};

export default $exports
